//13 * 25
// Space equals empty
// Number equals type of brick 0 to 6
// 0=VERT 1=VIOLET  2=BLEU  3=JAUNE  4=ROUGE  
//  5=BLANC  6=ORANGE 7=TURQUOISE 8=JAUNE FONCE  9=GRIS(dur)

const level5 = [ 
    "             ",
    "             ",
    "   3     3   ",
    "   3     3   ",
    "    3   3    ",
    "    3   3    ",
    "   9999999   ",
    "   9999999   ",
    "  994999499  ",
    "  994999499  ",
    " 99999999999 ",
    " 99999999999 ",
    " 99999999999 ",
    " 9 9999999 9 ",
    " 9 9     9 9 ",
    " 9 9     9 9 ",
    "    99 99    ",
    "    99 99    ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
];
const level4 = [ 
    "             ",
    "             ",
    " 67092 35670 ",
    " 70921 56709 ",
    " 09213 67092 ",
    " 92135 70921 ",
    " 21356 09213 ",
    " 13567 92135 ",
    " 35670 21356 ",
    " 56709 13567 ",
    " 67092 35670 ",
    " 70921 56709 ",
    " 09213 67092 ",
    " 92135 70921 ",
    " 21356 09218 ",
    " 13567 92185 ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
];

const level3 = [ 
    "             ",
    "             ",
    "0000000000000",
    "             ",
    "5558888888888",
    "             ",
    "4444444444444",
    "             ",
    "8888888888555",
    "             ",
    "1111111111111",
    "             ",
    "2228888888888",
    "             ",
    "7777777777777",
    "             ",
    "8888888888777",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
];

const level2 = [ 
    "             ",
    "5            ",
    "56           ",
    "567          ",
    "5670         ",
    "56704        ",
    "567042       ",
    "5670421      ",
    "56704213     ",
    "567042135    ",
    "5670421356   ",
    "56704213567  ",
    "567042135670 ",
    "9999999999994",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
];

const level1 = [ 
    "             ",
    "             ",
    "             ",
    "             ",
    "9999999999999",
    "4444444444444",
    "3333333333333",
    "2222222222222",
    "1111111111111",
    "0000000000000",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
];

const level0 = [ 
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
    "             ",
];

export const levelsDef = [
    level1,
    level2,
    level3,
    level4,
    level5,
]
